import { FC, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { UseMainDataContext } from "../state/MainState";
import { setProducts } from "../state/storestate";
import { GetProducts } from "./product";
import StoreCard from "./StoreCard";

export const StoreHome: FC = (props: any) => {
    const { State } = UseMainDataContext()
    const { Domain } = State

    const Products = useAppSelector((state) => state.store.Products)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (Products && Products.length === 0) {
            GetProducts(Domain!, 5)
                .then(data => {
                    dispatch(setProducts(data))
                }
                )
        }
    }, [Domain, dispatch])

    return (
        <section>
            <Container>
                <Row>
                    {Products?.map(product => {
                        return (
                            <Col lg={3} key={product.id}>
                                <StoreCard {...product} />
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </section>
    )
};