import axios from "axios";
import { PRODUCTS_URL } from "../../constants";
import { ResponseRoot } from "../common/Response";

export interface Product {
    id: number;
    name: string;
    slug: string;
    fullurl:string;
    description: string;
    thumbnail: string;
    content: string;
    videourl?: any;
    status: string;
    updatedon: Date;
    releasedate?: any;
    version: string;
    compatibility: string;
    download?: any;
    visible: string;
    sites: number[];
}
export async function GetProducts(domain: string, count: Number): Promise<Array<Product>> {

    var products: Array<Product>;

    await axios.get<ResponseRoot<Product>>("https://api." + domain + PRODUCTS_URL + "?max=" + count)
        .then(response => {
            products = response.data.results
        })
        .catch(error => {
            console.log(error.response);
        }
        )
    return products!;

}