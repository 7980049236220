import { actionTypes } from './MainState';
import { BlogPost as IBlogPost } from './../blog/blogpost';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IBlogstate {
    CurrentPost?: IBlogPost,
    RecentPosts: Array<IBlogPost>
    CurrentCategory?: undefined
}

const IntialBlogstate: IBlogstate = {
    CurrentPost: undefined,
    RecentPosts: []
}

const BlogSlice = createSlice({
    name: "blog",
    initialState: IntialBlogstate,
    reducers: {
        setblog: (state, action: PayloadAction<IBlogPost>) => {
            state.CurrentPost = action.payload
        },
        setRecentposts: (state, action: PayloadAction<Array<IBlogPost>>) => {
            state.RecentPosts = action.payload
        },
        setBlogState: (state, action: PayloadAction<IBlogstate>) => {
            state.RecentPosts = action.payload.RecentPosts
            state.CurrentPost = action.payload.CurrentPost
            state.CurrentCategory = action.payload.CurrentCategory
        }
    }

})
export const { setblog, setRecentposts, setBlogState } = BlogSlice.actions
export default BlogSlice.reducer