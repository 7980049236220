import { FC } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { FileEarmarkSpreadsheet } from "react-bootstrap-icons"
import { UseMainDataContext } from "../state/MainState";
export interface Feature {
    id: number,
    faeatureName: string,
    thummb: string,
    subfeatures?: Array<SubFeature>,
    description?: JSX.Element
}

export interface SubFeature {
    icon: JSX.Element,
    subHeading: string
    description: string
}

export const Features: Array<Feature> = [

    {
        id: 0,
        faeatureName: "Group Template",
        thummb: "/media/store/product/ProcessingGroup.png",
        description: <>This template enables you to Import and Export Groups to Tally<br />
            We can Import existing data from Tally, Make necessary changes and export in bulk</>
    },
    {
        id: 1,
        faeatureName: "Ledger Template",
        thummb: "/media/store/product/ProcessingLedger.png",
        description: <>This template enables you to Import and Export Ledgers to Tally<br />
            We can Import existing data from Tally, Make necessary changes and export in bulk</>
    },
    {
        id: 2,
        faeatureName: "Voucher Template",
        thummb: "/media/store/product/ProcessingVoucher.png",
        subfeatures: [
            {
                icon: <FileEarmarkSpreadsheet color="white" size={25} style={{ marginTop: "1em", marginBottom: "1em" }} />,
                subHeading: "Single Template",
                description: "Single Voucher Template for all Accounting Vouchers like Sales, Payment, Purchase ..etc,"
            },
            // {
            //     icon: <FileEarmarkSpreadsheet color="white" size={25} style={{ marginTop: "1em", marginBottom: "1em" }} />,
            //     subHeading: "StockItem",
            //     description: "We can add unlimited StockItems per Voucher and enable cost allocations at stockitem level"
            // },
            // {
            //     icon: <FileEarmarkSpreadsheet color="white" size={25} style={{ marginTop: "1em", marginBottom: "1em" }} />,
            //     subHeading: "Costcenters",
            //     description: "Enable Cost allocations and there is no limit"
            // },
            // {
            //     icon: <FileEarmarkSpreadsheet color="white" size={25} style={{ marginTop: "1em", marginBottom: "1em" }} />,
            //     subHeading: "Godowns/Location",
            //     description: "We can add entries with multiple godowns or locations"
            // },
            {
                icon: <FileEarmarkSpreadsheet color="white" size={25} style={{ marginTop: "1em", marginBottom: "1em" }} />,
                subHeading: "Ledgers",
                description: "We can add unlimited Ledgers"
            }
        ],
    },
]

export const ProductDetail: FC = (props: any) => {
    const { ToggleAuthModal, State } = UseMainDataContext()
    const { IsLoggedin } = State
    return (
        <>
            <Container fluid className="m-0 p-0">
                <Container>
                    <Row style={{ minHeight: "600px" }} >
                        <Col className="py-2" lg={6} style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <h1 style={{ fontSize: "4em" }}>Excel To Tally</h1>
                            <p style={{ fontSize: "2em" }}>With Excel To Tally you can transfer Accounting data in Excel to Tally in easier way</p>
                            <Button onClick={() => {
                                if (IsLoggedin) {
                                    return window.location.href = "https://accountingcompanion.com/apps/Excel To Tally/Excel To Tally.exe"
                                }
                                else {
                                    return ToggleAuthModal()
                                }
                            }} style={{ fontSize: "1.2em", minWidth: "12em" }} variant="primary">{IsLoggedin ? "Get Free Trial" : "Login to Get Free Trial"}</Button>
                        </Col>
                        <Col className="py-2" lg={6} style={{ marginTop: "auto", marginBottom: "auto" }} >
                            <Image width={"100%"} height={"140%"} src="https://saivineeth.com/media/store/product/Exceltotally.jpg" />
                        </Col>
                    </Row>
                </Container>
                <div className="text-white m-0 p-0" style={{ background: "#8BBEE8FF" }}>
                    <Container>
                        <div className="text-center">
                            <h2 style={{ fontSize: "3em" }}>Features</h2>
                        </div>
                        <div >
                            {Features.map((_feature) => { return <ProductFeature key={_feature.id} {..._feature} /> })}
                        </div>
                    </Container>
                </div>
            </Container>
        </>
    )
};

export const ProductFeature: FC<Feature> = (props: Feature) => {
    const { ToggleAuthModal, State } = UseMainDataContext()
    const { IsLoggedin,Subdomain,Domain } = State
    const iseven = props.id % 2 === 0
    const thumb = <Image className="img-fluid" height={"205%"}  src={`https://${Subdomain}.${Domain}${props.thummb}`} rounded />
    const Content = props.description == null ? <Row>{props.subfeatures?.map((subfeature) => { return <ProductSubFeature key={subfeature.subHeading} {...subfeature} /> })}</Row> : <p className="text-center" style={{ fontSize: "1.2rem" }}>{props.description}</p>
    const align = iseven ? "mr-auto" : "ml-auto"
    return (
        <Row className="py-5 text-white" >
            {/* <Col lg={iseven ? 5 : 7} style={{ marginTop: "auto", marginBottom: "auto" }}>
                {iseven && thumb}
                {!iseven && <h3 className="text-center mb-3">{props.faeatureName}</h3>}
                {!iseven && Content}
            </Col>
            <Col lg={!iseven ? 5 : 7} xs={{order:iseven ? 2:1}} style={{ marginTop: "auto", marginBottom: "auto" }}>
                {iseven && <h3 className="text-center mb-3">{props.faeatureName}</h3>}
                {iseven && Content}
                {!iseven && thumb}
            </Col> */}

            <Col lg={{ order: iseven ? 1 : 2, span: 5 }} className={"ml-auto py-2"} style={{ marginTop: "auto", marginBottom: "auto" }}>
                {thumb}
            </Col>
            <Col lg={{ order: !iseven ? 1 : 2, span: 7 }} className={"mr-auto py-2"} style={{ marginTop: "auto", marginBottom: "auto" }}>
                {<h3 className="text-center mb-3">{props.faeatureName}</h3>}
                {Content}
            </Col>


        </Row>);
}

export const ProductSubFeature: FC<SubFeature> = (props: SubFeature) => {
    return (
        <Col lg={6} >
            <Row>
                <Col xs={2} lg={3} style={{ marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto" }}>
                    <div className="rounded-circle text-center" style={{
                        backgroundColor: "#00DBDE", backgroundImage: "linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%)",
                        height: "4em", width: "4em"
                    }} >
                        {props.icon}
                    </div>
                </Col>
                <Col xs={10} lg={9}>
                    <h4 className="h5">{props.subHeading}</h4>
                    <p>{props.description}</p>
                </Col>
            </Row>
        </Col >
    )
}
