
import { FC, useEffect, useState } from "react"
import RecentBlogCard from "../../layout/components/blog/RecentBlogCard"
import { setRecentposts } from "../state/blogstate"
import { useAppDispatch, useAppSelector } from "../state/hooks"
import { UseMainDataContext } from "../state/MainState"
import { GetBlogposts } from "./blogpost"

const RecentPostsGadget: FC = () => {
    const { State } = UseMainDataContext()
    const { Domain } = State

    const RecentPosts = useAppSelector((state) => state.blog.RecentPosts)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (RecentPosts && RecentPosts.length === 0) {
            GetBlogposts(Domain!, 5)
                .then(data => {
                    dispatch(setRecentposts(data))
                }
                )
        }
    }, [Domain, dispatch])

    return (
        <aside className="aside-right mb-4">
            <div style={{ background: "black" }}>
                <h3 className="h5 text-white px-4 p-1"> RECENT POSTS</h3>
            </div>
            <div>
                {RecentPosts?.map(recentpost => { return <RecentBlogCard key={recentpost.id} {...recentpost} /> })}

            </div>
        </aside>
    )
}
export default RecentPostsGadget