import React, { createContext, useContext, useState } from "react";

export interface PageLink {
    title: string
    path: string
    isActive: boolean
    isSeparator?: boolean
}
export interface ViewDataContextModel {
    pageTitle?: string
    setPageTitle: (_title: string) => void
    pageDescription?: string
    setPageDescription: (_description: string) => void
    pageBreadcrumbs?: Array<PageLink>
    setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
}
const ViewDataContext = createContext<ViewDataContextModel>({
    setPageTitle: (_title: string) => { },
    setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => { },
    setPageDescription: (_description: string) => { },
})
const ViewDataProvider: React.FC = ({ children }) => {
    const [pageTitle, setPageTitle] = useState<string>('')
    const [pageDescription, setPageDescription] = useState<string>('')
    const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
    const value: ViewDataContextModel = {
        pageTitle,
        setPageTitle,
        pageDescription,
        setPageDescription,
        pageBreadcrumbs,
        setPageBreadcrumbs,
    }
    return <ViewDataContext.Provider value={value}>{children}</ViewDataContext.Provider>
}
function useViewData() {
    return useContext(ViewDataContext)
  }

  export { ViewDataProvider, useViewData}