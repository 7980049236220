
import { Routes, Route } from "react-router-dom";
import { BlogDetail } from "../app/modules/blog/BlogDetail";
import BlogHome from "../app/modules/blog/BlogHome";
import { Category_BlogDetailContainer } from "../app/modules/blog/Cat_BlogDetailCont";
import { UnderConstruction } from "../app/modules/UnderConstruction";


export function BlogRoutes() {
    
    return (
        <Routes>
            <Route path="/" element={<BlogHome/>} />
            {/* <Route path="/:category/" element={<BlogDetail/>} /> */}
            <Route path="/:category/*" element={<Category_BlogDetailContainer/>} />
        </Routes>)
}   