import React, { FC, Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../state/hooks";
import { UseMainDataContext } from "../state/MainState";
import { UnderConstruction } from "../UnderConstruction";
import { BlogPost, GetBlogPostBySlug, GetBlogposts } from "./blogpost";
import { setblog } from "../state/blogstate"
import BlogDetail from "./BlogDetail";


export const Category_BlogDetailContainer: FC<any> = (props: any) => {
    const { category, "*": resturl } = useParams()
    let splittedurls = resturl!.split("/")
    let lastslug = splittedurls[splittedurls.length - 1]
    lastslug = lastslug === "" ? splittedurls[splittedurls.length - 2] : lastslug

    const { CurrentPost: BlogPost, CurrentCategory: Category } = useAppSelector((state) => state.blog)
    const dispatch = useAppDispatch()
    const { ToggleAuthModal, State } = UseMainDataContext()
    const { IsLoggedin, Domain } = State
    const [isloading, setisloading] = useState(false)

    useEffect(() => {
        if (lastslug !== BlogPost?.slug) {
            setisloading(true)
            GetBlogPostBySlug(Domain, lastslug)
                .then(data => {
                    dispatch(setblog(data))
                })
                .catch(

                )
            setisloading(false)
        }

    }, [lastslug])


    return (
        <>
            { isloading && BlogPost === undefined && Category === undefined ? <UnderConstruction /> : <BlogDetail blogpost={BlogPost!} />}

        </>
    )
}