export function ShowFullUrl(domain: string | undefined, subdomain: string | undefined, url: string): string {
    let targeturl: Website = ParseDomain(url)

    if (domain === targeturl.domain && subdomain === targeturl.subdomain) {
        return targeturl.path
    }
    return url
}
export function Removehttp(url: string) {
    var Splittedurl = url.split("//")
    const cleanedurl = Splittedurl[Splittedurl.length - 1]
    return cleanedurl
}
export function ParseDomain(url?: string): Website {
    url = url ? Removehttp(url!) :url
    var splittedfulldomain = url?.split(".") ?? window.location.hostname.split(".")

    var subdomainsplit = splittedfulldomain[0].split("//")
    const subdomain = subdomainsplit[subdomainsplit.length - 1]

    let domain = splittedfulldomain[1]
    var temp = splittedfulldomain[2].split("/")
    domain = domain + "." + temp[0]

    const path = "/" + temp.slice(1).join("/")

    return { domain: domain, subdomain: subdomain, path: path }
}

export interface Website {
    domain: string,
    subdomain: string,
    path: string
}