import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product as IProduct } from "../store/product";

export interface IStoreState {
    CurrentProduct?: IProduct,
    Products: Array<IProduct>
}
const IntialStoreState: IStoreState = {
    CurrentProduct: undefined,
    Products: []
}

const StoreSlice = createSlice({
    name: "store",
    initialState: IntialStoreState,
    reducers: {
        setCurrentProduct: (state, action: PayloadAction<IProduct>) => {
            state.CurrentProduct = action.payload
        },
        setProducts: (state, action: PayloadAction<Array<IProduct>>) => {
            state.Products = action.payload
        },
        setStoreState: (state, action: PayloadAction<IStoreState>) => {
            state.CurrentProduct = action.payload.CurrentProduct
            state.Products = action.payload.Products
        }
    }

})
export const { setProducts, setCurrentProduct,setStoreState } = StoreSlice.actions
export default StoreSlice.reducer