import { FC } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { Product } from "./product"



const StoreCard: FC<Product> = (product: Product) => {


    return (
        <LinkContainer style={{ cursor: "pointer" }} to={product.fullurl}>
            <Card onClick={() => { }} className="my-3">
                <Card.Img style={{height:"15em"}} src={product.thumbnail} />
                <Card.Body style={{height:"10em"}}>
                    <div className="product-header">
                        <h2 style={{fontSize:"1.4em"}}>{product.name}</h2>
                    </div>
                    <div className="product-description">
                        <p>{product.description}
                        </p>
                    </div>
                </Card.Body>
                {/* <Card.Footer>
                <ul className="product-meta d-flex mb-0">
                    <li className="post-date"><i className="uil uil-calendar-alt"></i><span>26 Feb 2021</span></li>
                    <li className="post-comments"><a href="#"><i className="uil uil-comment"></i>6</a></li>
                    <li className="post-likes ms-auto"><a href="#"><i className="uil uil-heart-alt"></i>3</a></li>
                </ul>
            </Card.Footer> */}
            </Card>
        </LinkContainer>
    )
}
export default StoreCard