import { configureStore } from '@reduxjs/toolkit'
import BlogReducer from './blogstate'
import StoreReducer from './storestate'

export const store = configureStore({
    reducer: {
        blog: BlogReducer,
        store: StoreReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch