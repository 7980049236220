import axios from "axios"
import { config } from "process"
import { EmojiAngryFill } from "react-bootstrap-icons"
import { GETUSER_URL, LOGIN_URL, SIGNUP_URL } from "../../constants"

export interface AuthRoot {
    tokens: Tokens;
    user: User;
    usertype: string;
    error?: string
}

export interface Tokens {
    refresh: string;
    access: string;
}

export interface User {
    id: number;
    licenses: License[];
    last_login: Date;
    is_superuser: boolean;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_staff: boolean;
    is_active: boolean;
    date_joined: Date;
    phone_number: null;
    dp: null;
    groups: any[];
    user_permissions: any[];
    organisations: number[];
}

export interface License {
    id: number;
    product: string;
    users: string[];
    organisation: null | string;
    plan: string;
    devices: string[];
    purchasedate: Date;
    validupto: Date;
    maxdevicescount: number;
}


export const Login = async (domain:string|undefined,username: string, password: string) => {
    var loginresponse: any
    await axios.post<AuthRoot>("https://api." + domain + LOGIN_URL, { username: username, password: password })
        .then(response => {
            loginresponse = response.data
        })

    return loginresponse
}
export const Signup = async (domain:string|undefined,username: string, password: string, email: string, password2: string, agreeterms: string) => {
    var Signupresponse: any
    await axios.post<AuthRoot>("https://api." + domain + SIGNUP_URL, { username: username, email: email, password: password, password2: password2, agreeterms: agreeterms })
        .then(response => {
            Signupresponse = response.data
        })

    return Signupresponse
}

export const GetUser = async (domain:string|undefined,acesstoken: string) => {
    var loginresponse
    await axios.get<User>("https://api." + domain + GETUSER_URL, {
        headers: { Authorization: "Bearer " + acesstoken }
    })
        .then((response: any) => {
            loginresponse = response.data
        })

    return loginresponse
}