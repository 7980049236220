import { FC } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useAppSelector, useAppDispatch } from "../state/hooks"
import BlogPostCard from "./PostCard"
import RecentPostsGadget from "./RecentPosts"

const BlogHome: FC = () => {

    const recentPosts = useAppSelector((state) => state.blog.RecentPosts)
    const dispatch = useAppDispatch()

    return (<>
        <section className="py-5" style={{}}>
            <Container>
                <Row>
                    <Col xl={8}>
                        <Row className="g-0">
                            {recentPosts?.map(post => { return <Col className="m-0 p-0" md={6} xl={12}> <BlogPostCard key={post.id} {...post} /></Col> })}
                        </Row>
                    </Col>
                    <Col xl={4}>
                        <div className="aside-container pt-2 sticky-top-md" style={{ top: "66px" }}>
                            <RecentPostsGadget />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>)
}
export default BlogHome