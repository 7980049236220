import { Col, Container, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom"

import RecentPostsGadget from "./RecentPosts";

import "./BlogDetail.css"

import { FC, useEffect } from "react";

import hljs from 'highlight.js/lib/core';
import { BlogPost } from "./blogpost";
import { Helmet } from 'react-helmet';
import { UseMainDataContext } from "../state/MainState";
hljs.registerLanguage('C#', require('highlight.js/lib/languages/csharp'));
hljs.registerLanguage('python', require('highlight.js/lib/languages/python'));

hljs.registerLanguage('powershell', require('highlight.js/lib/languages/powershell'));

export const BlogDetail: FC<{ blogpost: BlogPost }> = (props) => {
    const { State } = UseMainDataContext()
    const { Domain } = State

    useEffect(() => {
        hljs.highlightAll()
    }, [props.blogpost])

    return (
        <>
            <Helmet>
                <title> {props.blogpost?.title + "| post from" + Domain}</title>
                <meta property="og:type" content="article" />
                <meta property="og:title" content={props.blogpost?.title} />
                <meta property="og:description" content={props.blogpost?.description} />
                <meta property="og:image" content={props.blogpost?.thumbnail} />
                <meta property="og:site_name" content={Domain} />

            </Helmet>
            <section className="py-5">
                <Container>
                    <Row>
                        <Col className="content p-0" lg={8}>
                            <div className="blog-header  py-4">
                                <div className="p-2">
                                    <h1 className="post-title">{props.blogpost?.title}</h1>
                                    <h4 className="post-desc">{props.blogpost?.description}</h4></div>
                                <Image className="rounded" fluid src={props.blogpost?.thumbnail} />
                            </div>

                            <div className="p-3" dangerouslySetInnerHTML={{ __html: props.blogpost?.content }} />
                        </Col>
                        <Col lg={4}>
                            <div className="aside-container pt-2 sticky-top-md" style={{ top: "65px" }}>
                                <RecentPostsGadget />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )

}
export default BlogDetail