import { Container } from "react-bootstrap"
import NavigationMenu from "../../modules/navigation/Menu"
import Footer from "./footer"
import { ViewDataProvider } from "./ViewData"

const MasterLayout: React.FC = ({ children }) => {

    return (
        <ViewDataProvider>
            <Container fluid>
                <header className="bg-light sticky-top">
                    <NavigationMenu />
                </header>

                {children}
                <Footer/>

            </Container>
        </ViewDataProvider>)
}
export { MasterLayout }