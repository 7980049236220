import { Routes, Route } from "react-router-dom";
import { ProductDetail } from "../app/modules/store/Product_Detail";
import { StoreHome } from "../app/modules/store/StoreHome";
import { UnderConstruction } from "../app/modules/UnderConstruction";

export function StoreRoutes() {
    return (
        <Routes>
            <Route path="/" element={<StoreHome/>} />
            <Route path="/product/excel-to-tally/" element={<ProductDetail />} />
        </Routes>)
}