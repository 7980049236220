import { Routes, Route } from "react-router-dom";
import { Home } from "../app/modules/Home";
import { UnderConstruction } from "../app/modules/UnderConstruction";


export function DefaultRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about/" element={<UnderConstruction/>} />
        </Routes>)
}