import { FC } from "react"
import { Card, Row, Col, Image } from "react-bootstrap"
import { Link } from "react-router-dom"
import { ShowFullUrl } from "../../../../utils/urlhelper"
import { BlogPost } from "../../../modules/blog/blogpost"
import { UseMainDataContext } from "../../../modules/state/MainState"

const RecentBlogCard: FC<BlogPost> = (blogpost) => {
    return (
        <Card  className="my-3">
            <Row >
                <Col lg={5}  >
                    <Image fluid src={blogpost.thumbnail} />
                </Col>
                <Col lg={7} style={{height:"4em"}}>
                    <Link to={blogpost.fullurl}>{blogpost.title}</Link>
                </Col>
            </Row>
        </Card>
    )
}
export default RecentBlogCard