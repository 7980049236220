import axios from "axios";
import { POSTS_URL } from "../../constants";
import { ResponseRoot } from "../common/Response"

export interface BlogPost {
    id: number;
    category: string;
    author: string;
    sites: string[];
    hit_count: number;
    title: string;
    slug: string;
    description: string;
    thumbnail?: any;
    content: string;
    publisheddat: Date;
    lastupdated: Date;
    surl?: string;
    fullurl:string
}


export async function GetBlogposts(domain: string, count: Number): Promise<Array<BlogPost>> {

    var posts: Array<BlogPost>;

    await axios.get<ResponseRoot<BlogPost>>("https://api." + domain + POSTS_URL + "?max=" + count)
        .then(response => {
            posts = response.data.results
        })
        .catch(error => {
            console.log(error.response);
        }
        )
    return posts!;

}

export async function GetBlogPostBySlug(domain: string | undefined, slug: string): Promise<any> {
    let post
    await axios.get<BlogPost>("https://api." + domain + POSTS_URL + slug +"/")
        .then(Response => { post = Response.data })
        .catch()
    return post
}
