import { FC } from "react";
import { MasterLayout } from "../app/layout/core/MasterLayout";
import { UseMainDataContext } from "../app/modules/state/MainState";
import { BlogRoutes } from "./BlogRoutes";
import { DefaultRoutes } from "./DefaultRoutes";
import { DocsRoutes } from "./DocsRoutes";
import { StoreRoutes } from "./StoreRoutes";


export const Routes: FC = (props) => {
    const { State: { Subdomain } } = UseMainDataContext()
    //const { Subdomain } = State

    var routes
    if (Subdomain === "blog") {
        routes = <BlogRoutes />
    }
    else if (Subdomain === "store") {
        routes = <StoreRoutes />
    }
    else if (Subdomain === "docs") {
        routes = <DocsRoutes />
    }
    else {
        routes = <DefaultRoutes />
    }
    return (
        <MasterLayout>
            {routes}
        </MasterLayout>
    )
}