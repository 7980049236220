import { Console } from "console";
import { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import { useCookies } from "react-cookie";
import { ParseDomain, Website } from "../../../utils/urlhelper";
import { AuthRoot, GetUser, Tokens, User } from "../auth/Auth";
import { setBlogState } from "./blogstate";
import { useAppDispatch } from "./hooks";
import { setStoreState } from "./storestate";



export const actionTypes = {
    SetState: '[SetState] Action',
    UpdateSubdomain: '[UpdateSubdomain] Action',
    UpdateDomain: '[UpdateDomain] Action',
    Login: '[Login] Action',
    Logout: '[Logout] Action',
    SetUser: '[SetUser] Action',
    ToggleForm: 'ToggleForm',
}
export const AuthFormtypes = {
    Login: '[Login] Form',
    Signup: '[Logout] Signup',
}
export interface Auth {
    Form: string
    visible: Boolean
}
export interface IMainState {
    Domain?: string,
    Subdomain?: string
    IsLoggedin: boolean
    AccessToken?: string
    RefreshToken?: string
    User?: User
    Auth: Auth
}
export interface IMainContext {
    State: IMainState,
    UpdateDomain: (name: string) => void,
    UpdateSubdomain: (name: string) => void,
    ToggleAuthModal: (Formtype?: string) => void
    Login: (Loginpayload: AuthRoot) => void,
    Logout: () => void,
    SetUser: (tokens: Tokens, User: User) => void,
    SetState: (state: IMainState) => void
}
const IntialMainState: IMainState = {
    Domain: undefined,
    Subdomain: undefined,
    IsLoggedin: false,
    AccessToken: undefined,
    RefreshToken: undefined,
    User: undefined,
    Auth: {
        Form: AuthFormtypes.Login,
        visible: false
    }

}
const IntialContext: IMainContext = {
    State: IntialMainState,
    UpdateDomain: (name: string) => { },
    UpdateSubdomain: (name: string) => { },
    ToggleAuthModal: (Formtype?: string) => { },
    Login: (Loginpayload: AuthRoot) => { },
    Logout: () => { },
    SetUser: (tokens: Tokens, User: User) => { },
    SetState: (state: IMainState) => { },
}
export const MainDataContext = createContext<IMainContext>(IntialContext)

const MainStateReducer = (state: IMainState, action: any) => {

    switch (action.type) {
        case actionTypes.UpdateSubdomain: {
            const name = action.subdomainname
            var newstate = { ...state }
            newstate.Subdomain = name
            return newstate;
        }
        case actionTypes.UpdateDomain: {
            const name = action.domain
            var newstate = { ...state }
            newstate.Domain = name
            return newstate;
        }
        case actionTypes.ToggleForm: {
            const formtype = action.Formtype
            const newstate: IMainState = {
                ...state,
                Auth: {
                    Form: formtype ? formtype : state.Auth.Form,
                    visible: formtype ? state.Auth.visible : !state.Auth.visible
                }
            }
            return newstate
        }
        case actionTypes.Login: {
            const RootData: AuthRoot = action.Loginpayload
            const newstate: IMainState = {
                ...state,
                IsLoggedin: true,
                User: RootData.user,
                AccessToken: RootData.tokens.access,
                RefreshToken: RootData.tokens.refresh,
                Auth: {
                    visible: false,
                    Form: "",
                }
            }
            return newstate
        }
        case actionTypes.Logout: {
            return {
                ...IntialMainState,
                Subdomain: state.Subdomain,
                Domain: state.Domain,
            }
        }
        case actionTypes.SetUser: {
            const User: User = action.User
            const newstate: IMainState = {
                ...state,
                IsLoggedin: true,
                User: User
            }
            return newstate
        }
        case actionTypes.SetState: {
            newstate = { ...state, ...action.newstate }
            return newstate
        }
        default:
            return state;
    }
}

export const MainInit: React.FC = (props: any) => {
    const [MainState, dispatch] = useReducer(MainStateReducer, IntialMainState);
    const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);
    const Reduxdispatch = useAppDispatch()

    var tempvalue: any = useMemo(() => (
        {
            UpdateSubdomain: (name: string) => {
                dispatch({ type: actionTypes.UpdateSubdomain, subdomainname: name })
            },
            UpdateDomain: (name: string) => {
                dispatch({ type: actionTypes.UpdateDomain, domain: name })
            },
            ToggleAuthModal: (Formtype?: string) => {
                dispatch({ type: actionTypes.ToggleForm, Formtype: Formtype })
            },
            Login: (Loginpayload: AuthRoot) => { dispatch({ type: actionTypes.Login, Loginpayload: Loginpayload }) },

            SetUser: (tokens: Tokens, User: User) => { dispatch({ type: actionTypes.SetUser, tokens: tokens, User: User }) },
            SetState: (newstate: IMainState) => { dispatch({ type: actionTypes.SetState, newstate: newstate }); }

        }), [])

    var value: IMainContext = useMemo(() => ({
        State: MainState,
        ...tempvalue,
        Logout: () => {
            removeCookie("access_token", { domain: "." + MainState.Domain, path: '/' });
            removeCookie("refresh_token", { domain: "." + MainState.Domain, path: '/' });
            dispatch({ type: actionTypes.Logout })
        },
    }), [MainState, tempvalue])

    // SSR
    if (props.context) {
        if (props.context.MainState) {
            value.SetState(props.context.MainState)
            props.context.MainState = undefined
        }
        if (props.context.BlogState) {
            Reduxdispatch(setBlogState(props.context.BlogState))
            props.context.BlogState = undefined
        }
        if (props.context.StoreState) {
            Reduxdispatch(setStoreState(props.context.StoreState))
            props.context.StoreState = undefined
        }
    }

    // Settimg state received from servver
    useEffect(() => {
        const elem = document.getElementById('IntialState')
        if (elem) {
            const IntialState = JSON.parse(elem!.textContent as string);
            if (IntialState.MainState) {
                value.SetState(IntialState.MainState)
            }

            if (IntialState.BlogState) {
                Reduxdispatch(setBlogState(IntialState.BlogState))
            }
            if (IntialState.StoreState) {
                Reduxdispatch(setStoreState(IntialState.StoreState))
            }
            elem.remove()
        }
    }, [])

    useEffect(() => {
        if (value.State.Domain === undefined || value.State.Subdomain === undefined) {
            var Currenturl: Website = ParseDomain()
            value.UpdateSubdomain(Currenturl.subdomain)
            value.UpdateDomain(Currenturl.domain)
        }
    }, [value.State.Domain, value.State.Subdomain])

    useEffect(() => {

        if (value.State.Domain && cookies.access_token && value.State.User === undefined) {
            GetUser(value.State.Domain, cookies.access_token).then((response) => {
                value.SetUser({ access: cookies.access_token, refresh: cookies.refresh_token }, response!)
            })
        }
    }, [value.State.Domain])

    return (<MainDataContext.Provider value={value}>
        {props.children}
    </MainDataContext.Provider>)
}

export function UseMainDataContext() {
    return useContext(MainDataContext)
}