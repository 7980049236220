import { FC } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { BlogPost } from "./blogpost"
import './PostCard.css'
const BlogPostCard: FC<BlogPost> = (post: BlogPost) => {


    return (
        <LinkContainer style={{ cursor: "pointer" }} to={post.fullurl}>
            <Card key={post.id} className="my-3 mx-md-2 blog-card">
                <Row className="g-0">
                    <Col xl={6}>
                        <Card.Img  alt={post.title} className="img-fluid rounded blog-img" src={post.thumbnail} />
                    </Col>
                    <Col xl={6}>
                        <Card.Body>
                            <Card.Title className="blog_title">{post.title}</Card.Title>
                            <Card.Text className="blog-descr">
                                {post.description}
                            </Card.Text>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </LinkContainer>

    )
}
export default BlogPostCard