import { FC } from "react"
import  Container  from "react-bootstrap/Container"
import { UseMainDataContext } from "../../modules/state/MainState"

const Footer: FC = () => {
    const { State } = UseMainDataContext()
    const { Domain, IsLoggedin } = State

    return (<>
        <footer className="bg-dark footer">
            <Container className="text-white">
                <div className="container fotter-bottom bg-dark text-center">
                    <p className="p-0">© 2021-22 {Domain?.split(".")[0]}. All Rights Reserved</p>
                    <p className="p-0">Mail - contact@saivineeth.com</p>
                </div>
            </Container>
        </footer>
    </>)
}
export default Footer