import React, { FC, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BlogPost, GetBlogposts } from "./blog/blogpost";
import BlogPostCard from "./blog/PostCard";
import { setRecentposts } from "./state/blogstate";
import { useAppSelector, useAppDispatch } from "./state/hooks";
import { UseMainDataContext } from "./state/MainState";
import { setProducts } from "./state/storestate";
import { GetProducts } from "./store/product";
import StoreCard from "./store/StoreCard";

const Home: FC = (props: any) => {
  const { State } = UseMainDataContext()
  const { Domain } = State

  const Products = useAppSelector((state) => state.store.Products)
  const RecentPosts = useAppSelector((state) => state.blog.RecentPosts)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (Domain && Products && Products.length === 0) {
      GetProducts(Domain!, 5)
        .then(data => {
          dispatch(setProducts(data))
        }
        )
    }
  }, [Domain, dispatch])

  useEffect(() => {
    if (Domain && RecentPosts && RecentPosts.length === 0) {
      GetBlogposts(Domain!, 5)
        .then(data => {
          dispatch(setRecentposts(data))
        }
        )
    }
  }, [Domain, dispatch])
  return (

    <Container>
      <Row>

        <Col xl={8}>
          <Row>
            {Products?.map(product => {
              return (
                <Col md={6} lg={4} key={product.id}>
                  <StoreCard {...product} />
                </Col>
              )
            })}
          </Row>
          <Row>
            <Col>
              <Row className="g-0">
                {RecentPosts?.map(post => { return <Col className="m-0 p-0" md={6} xl={12}> <BlogPostCard key={post.id} {...post} /></Col> })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>

  )
};

export { Home };
