
//import logo from './logo.svg';

import './App.css';
import { Routes } from '../routing/Routes';
import { MainInit } from './modules/state/MainState';


function App() {

  return (
    <MainInit>
      <Routes />
    </MainInit>
  )
}

export default App;
